import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Document } from '@contentful/rich-text-types';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      marginTop: '2em',
      marginBottom: '2em',
    },
    image: {
      height: 30,
      marginRight: '0.83em',
    },
    title: {
      marginBottom: 0,
      color: 'black',
    },
  })
);

interface Props {
  titleSize?: 'small' | 'medium' | 'large';
  title?: string;
  icon?: string;
  document?: Document | null | undefined;
  table?: string | null | undefined;
  card?: boolean;
  children?: React.ReactNode;
}

const IconTitle = ({
  titleSize,
  title,
  icon,
  children,
}: // card,
Props): JSX.Element => {
  const classes = useStyles();

  // let cardOption = '';

  // if (card) {
  //   cardOption = '&layout=card';
  // }


  return (
    <>
      <div className={classes.container}>
        {icon && <img className={classes.image} alt={title} src={icon} style={{width:(title == "Reino Unido"?"45px":"auto")}} />}
        <h2
          style={
            titleSize === 'medium'
              ? { fontSize: 20, color: 'black', fontWeight: 600 }
              : { fontSize: '1.5em', color: 'black', fontWeight: 600 }
          }
          className={classes.title}
        >
          {title}
        </h2>
      </div>
      {children}
    </>
  );
};

IconTitle.defaultProps = {
  titleSize: 'large',
  document: undefined,
  table: undefined,
  card: false,
  icon: undefined,
};

export default IconTitle;

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import type {
  Row3 as Row3Props,
  Row5 as Row5Props,
} from '../models/EstrategiaDeInversionTables';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& hr': {
        borderColor: '#959390',
      },
      '& *': {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
      },
    },
    guiaColores: {
      marginBottom: 35,
      '& div': {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
      },
      '& .circleBig': {
        width: 15,
        height: 15,
        display: 'inline-table',
        borderRadius: 15,
        marginRight: 10,
        background: '#C21212',
        '&-green': {
          background: '#0C5300',
        },
      },
    },
    subtitle: {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontSize: 16,
      maxWidth: 280,
      margin: '0 auto 35px',
      fontWeight: 'bold',
    },
    tableTitle: {
      fontSize: 14,
      background: theme.palette.primary.main,
      color: '#fff',
      padding: '3px 15px',
      marginTop: 20,
      marginBottom: 10,
    },
    tableHead: {
      padding: '2px 2px 2px 2px',
      background: '#959390',
      color: '#fff',
    },
    table: {
      width: '100%',
      '& .MuiTableCell-sizeSmall': {
        padding: '2px 2px 2px 2px',
        fontSize: 14,
      },
      '& .circle': {
        width: 15,
        display: 'block',
        height: 15,
        borderRadius: 10,
        margin: '0 auto',
        '&-green': {
          background: '#1CCF24',
        },

        '&-orange': {
          background: '#FF6600',
        },
        '&-yellow': {
          background: '#FFD600',
        },
        '&-red': {
          background: 'red',
        },

        '&-green2': {
          background: 'green',
        },
      },
      '& th.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall': {
        background: '#F9F9F9',
        fontSize: 14,
      },
    },
  })
);

const tables3Columns = {
  columns: [
    'Instrumento',
    'Análisis técnico',
    'Análisis fundamental',
    'Flujos',
  ],
};

const tables2Columns = {
  columns: ['Sector', 'México', 'Estados Unidos'],
};

interface Props {
  type?: '5-columns' | '3-columns' | '2-columns' | 'Texto' | undefined;
  recommendedActions?: boolean;
  title?: string;
  subtitle?: string;
  rows?: any;
  columns?: string[];
}

const TableStrategy = ({
  type,
  recommendedActions,
  subtitle,
  title,
  rows,
  columns,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* Tabla 2 */}
      {type === '5-columns' ? (
        <>
          <div className={classes.tableTitle}>{title}</div>
          {subtitle && <div style={{ marginBottom: 10 }}>{subtitle}</div>}
          {/* <hr style={{ marginBottom: 10 }} /> */}
          <TableContainer>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell
                    className={classes.tableHead}
                    align="center"
                    style={{
                      padding: '2px 2px 2px 2px',
                      background: '#959390',
                      color: '#fff',
                    }}
                  >
                    --
                  </TableCell>
                  <TableCell align="center" className={classes.tableHead}>
                    -
                  </TableCell>
                  <TableCell align="center" className={classes.tableHead}>
                    N
                  </TableCell>
                  <TableCell align="center" className={classes.tableHead}>
                    +
                  </TableCell>
                  <TableCell align="center" className={classes.tableHead}>
                    ++
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: Row5Props) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">
                      {row.dminus && <i className="circle circle-red" />}
                    </TableCell>
                    <TableCell align="center">
                      {row.minus && <i className="circle circle-orange" />}
                    </TableCell>
                    <TableCell align="center">
                      {row.neutral && <i className="circle circle-yellow" />}
                    </TableCell>
                    <TableCell align="center">
                      {row.plus && <i className="circle circle-green" />}
                    </TableCell>
                    <TableCell align="center">
                      {row.dplus && <i className="circle circle-green2" />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        ''
      )}

      {/* Tablas Acciones Recomendadas */}

      {recommendedActions && (
        <>
          <hr style={{ marginTop: 20, marginBottom: 20 }} />

          <div className={classes.guiaColores}>
            <b>Guía de colores:</b>
            <div>
              <i className="circleBig circleBig-green" />
              <b>: + Sobre-ponderado,</b>&nbsp;subir exposición
            </div>
            <div>
              <i className="circleBig" />
              <b>: - Sub-ponderado,</b>&nbsp;bajar exposición
            </div>
          </div>
        </>
      )}
      {type === '3-columns' && (
        <>
          <div className={classes.tableTitle}>{title}</div>
          {subtitle && <div style={{ marginBottom: 10 }}>{subtitle}</div>}

          <TableContainer>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  {tables3Columns.columns.map(column => (
                    <TableCell
                      style={{
                        color: 'grey',
                        background: '#F9F9F9',
                        lineHeight: 1.4,
                        padding: 5,
                        fontSize: 12,
                        textAlign: column === 'Instrumento' ? 'left' : 'center',
                      }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row: Row3Props) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        type === '3-columns' ? classes.guiaColores : ''
                      }
                    >
                      {row.column1 === 1 && (
                        <i className="circleBig circleBig-green" />
                      )}
                      {row.column1 === -1 && <i className="circleBig" />}
                    </TableCell>
                    <TableCell align="center" className={classes.guiaColores}>
                      {row.column2 === 1 && (
                        <i className="circleBig circleBig-green" />
                      )}
                      {row.column2 === -1 && <i className="circleBig" />}
                    </TableCell>
                    <TableCell align="center" className={classes.guiaColores}>
                      {row.column3 === 1 && (
                        <i className="circleBig circleBig-green" />
                      )}
                      {row.column3 === -1 && <i className="circleBig" />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {type === 'Texto' && (
        <>
          <div className={classes.tableTitle}>{title}</div>
          {subtitle && <div style={{ marginBottom: 10 }}>{subtitle}</div>}

          <TableContainer>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: 'grey',
                      background: '#F9F9F9',
                      lineHeight: 1.4,
                      padding: 5,
                      fontSize: 12,
                    }}
                  >
                    Divisa
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: 'grey',
                      background: '#F9F9F9',
                      lineHeight: 1.4,
                      padding: 5,
                      fontSize: 12,
                    }}
                  >
                    Piso
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: 'grey',
                      background: '#F9F9F9',
                      lineHeight: 1.4,
                      padding: 5,
                      fontSize: 12,
                    }}
                  >
                    Techo
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: 'grey',
                      background: '#F9F9F9',
                      lineHeight: 1.4,
                      padding: 5,
                      fontSize: 12,
                    }}
                  >
                    6 meses
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: Row3Props) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.column1}</TableCell>
                    <TableCell align="center" className={classes.guiaColores}>
                      {row.column2}
                    </TableCell>
                    <TableCell align="center" className={classes.guiaColores}>
                      {row.column3}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {type === '2-columns' && (
        <>
          <div className={classes.tableTitle}>{title}</div>
          {subtitle && <div style={{ marginBottom: 10 }}>{subtitle}</div>}

          <TableContainer>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  {tables2Columns.columns.map(column => (
                    <TableCell
                      style={{
                        color: 'grey',
                        background: '#F9F9F9',
                        lineHeight: 1.4,
                        padding: 5,
                        fontSize: 12,
                        textAlign: column === 'Sector' ? 'left' : 'center',
                      }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row: Row3Props) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        type === '2-columns' ? classes.guiaColores : ''
                      }
                    >
                      {row.column1 === 1 && (
                        <i className="circleBig circleBig-green" />
                      )}
                      {row.column1 === -1 && <i className="circleBig" />}
                    </TableCell>
                    <TableCell align="center" className={classes.guiaColores}>
                      {row.column2 === 1 && (
                        <i className="circleBig circleBig-green" />
                      )}
                      {row.column2 === -1 && <i className="circleBig" />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

TableStrategy.defaultProps = {
  type: '5-columns',
  title: '',
  subtitle: '',
  recommendedActions: true,
};

export default TableStrategy;

import React from 'react';
import { graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import { ContentfulEstrategiaDeInversion } from '../../types/graphql-types';
import { useEstrategiaDeInversionPost } from '../operations/queries';
import { useEstrategiaDeInversionTables } from '../operations/queries';
import PostLayout from '../components/PostLayout';
import { PostPreview as PostPreviewProps } from '../models/PostPreview';
import { EstrategiaDeInversion as EstrategiaDeInversionProps } from '../models/EstrategiaDeInversion';
import { EstrategiaDeInversionTables as EstrategiaDeInversionTableProps } from '../models/EstrategiaDeInversionTables';
import { Table as TableProps } from '../models/EstrategiaDeInversionTables';
import getCategoryColor from '../utils/getCategoryColor';

import IconTitle from '../components/IconTitle';

import World from '../assets/icons/icon-WorldMarkets.svg';

import TableEstrategy from '../components/TableEstrategy';
import options from '../utils/getRichTextOptions';

import { renderRichText } from "gatsby-source-contentful/rich-text"
import useIsClient from '../utils/isClient';
import { HeadApi } from '../components/HeadApi';

interface Props {
  data: {
    contentfulEstrategiaDeInversion: ContentfulEstrategiaDeInversion;
    mongodbDireccionEstrategiadeinversion: EstrategiaDeInversionTableProps;
  };
}

export const Head = ({data}) => {
  const post: EstrategiaDeInversionProps = useEstrategiaDeInversionPost(
    data.contentfulEstrategiaDeInversion
  );
  // console.log("[POST HEAD]", post);

  let highlightImg: any = undefined;
  try {
      highlightImg = post.fluid.gatsbyImageData.images.fallback.src;
  } catch (error) {
      try {
          highlightImg = post.fluid.gatsbyImageData.images.sources[0].srcSet;
      } catch (error2) {
          highlightImg = "https://vectoranalisis.mx/static/images/vector/analisis.png";
      }
  }
  return(
    <HeadApi
      title={post.title}
      description={post.description}
      img={highlightImg}
    />
  );
};

export default function EstrategiaInversion({ data }: Props): JSX.Element {
  const color = getCategoryColor('general');
  const post: EstrategiaDeInversionProps = useEstrategiaDeInversionPost(
    data.contentfulEstrategiaDeInversion
  );

  const tables: any = useEstrategiaDeInversionTables(
    data.allMongodbDireccionEstrategiadeinversion.edges[0].node
  );

  const postPreview = (): PostPreviewProps => {
    const {
      title,
      description,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      author,
      authorObject,
      content,
    } = post;
    return {
      title,
      description,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      author,
      content,
      authorObject,
    };
  };

  // console.log(postPreview())

  let recommendedActions = false;
  let threeColumnsCount = 0;

  const isClient = useIsClient();

  if(isClient){

    return (
      <>
        <PostLayout post={postPreview()} >
          <h2
            style={{
              paddingRight: '10px',
              paddingLeft: '20px',
              fontSize: '1.3em',
            }}
          >
            {post.description}
          </h2>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={3}>
              <IconTitle
                titleSize="medium"
                title="Estrategia de Asignación (horizonte 6-12 meses)"
                icon={World}
              >
                <>
                  {tables &&
                    tables.tables.map((table: TableProps) => {
                      if (table.type === '3-columns') {
                        recommendedActions = true;
                        threeColumnsCount++;
                      } else {
                        recommendedActions = false;
                        //threeColumnsCount++;
                      }
                      return (
                        <TableEstrategy
                          title={table.title}
                          type={table.type}
                          rows={table.rows}
                          columns={table.columns}
                          recommendedActions={
                            //to set legend only once
                            threeColumnsCount == 1 && recommendedActions
                          }
                        />
                      );
                    })}
                </>
              </IconTitle>
            </Grid>
  
            <Grid item xs={12} sm={12} md={6}>
              {post?.textInternationalAnalysis && (
                <IconTitle title="Análisis Internacional" icon={World}>
                  {renderRichText(
                    post.textInternationalAnalysis,
                    options
                  )}
                </IconTitle>
              )}
              {post?.textNationalEconomy && (
                <IconTitle title="Economía Nacional" icon={World}>
                  {renderRichText(post.textNationalEconomy, options)}
                </IconTitle>
              )}
              {post?.textPolitics && (
                <IconTitle title="Política" icon={World}>
                  {renderRichText(post.textPolitics, options)}
                </IconTitle>
              )}
              {post?.textStrategy && (
                <IconTitle title="Estrategia" icon={World}>
                  {renderRichText(post.textStrategy, options)}
                </IconTitle>
              )}
            </Grid>
          </Grid>
        </PostLayout>
      </>
    );
  }else{
    return(<></>)
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulEstrategiaDeInversion(slug: { eq: $slug }) {
      ...EstrategiaDeInversion
    }

    allMongodbDireccionEstrategiadeinversion(
      filter: { slug: { eq: $slug } }
    ) {
      edges {
        node {
          ...EstrategiaDeInversionTable
        }
      }
    }
  }
`;
